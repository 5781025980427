<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('language-modal', {id: 0},shown,{width: '800px'})"
                        variant="primary"
                        class="mb-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">

                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.language.label.id') }}</b-th>
                            <b-th>{{ $t('settings.language.label.code') }}</b-th>
                            <b-th>{{ $t('settings.language.label.name') }}</b-th>
                            <b-th>{{ $t('settings.language.label.actions') }}</b-th>
                        </b-thead>
                        <tbody>
                        <b-tr v-for="language in languages()" :key="language.id">
                            <b-td>{{ language.id }}</b-td>
                            <b-td>{{ language.code }}</b-td>
                            <b-td>{{ language.name }}</b-td>
                            <b-td>
                                <b-button
                                    @click="$root.$children[0].openModal('language-modal', {id: language.id},shown,{width: '800px'})"
                                    size="sm"
                                    variant="primary"
                                    class="mr-2"
                                    :title="$t('common.title.edit')"
                                >
                                    <font-awesome-icon icon="edit"/>
                                </b-button>

                                <b-button @click="deleteLanguage(language.id)"
                                          size="sm"
                                          variant="danger"
                                          :title="$t('common.title.delete')"
                                >
                                    <font-awesome-icon icon="trash"/>
                                </b-button>
                            </b-td>
                        </b-tr>
                        </tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsLanguages',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
        }
    },
    methods: {
        ...mapGetters('I18NStore', ['languages']),
        shown() {
            const languagesFetch = this.$store.dispatch('I18NStore/fetchLanguages')

            Promise.all([languagesFetch])
                .finally(() => {
                    this.loading = false
                })
            this.$root.afterSettingsUpdate()
        },
        deleteLanguage(id) {
            this.$removeConfirm('I18NStore/deleteLanguage', id, this.shown)
        },
    },
    mounted() {
        this.shown()
    }
}
</script>